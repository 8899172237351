import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { proxy, useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { WatchListWithChart } from '~/modules/symbolQuote/simple'
import IntradayTrendChart from '~/modules/trendChart'
import { WatchSymbolToggleSelector } from '../heineken_template/_col/col_WatchSymbolToggleGroupSelector'
import { store } from '../heineken_template/_private/store'
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { SymbolInfoForm } from '../daddy960_goodway/component/SymbolInfoForm'
import { AppLink2 } from '~/components/AppLink2'
import { stocknerve_store } from './daddy960_stocknerve_SidePane1'
import { AnyFunction } from 'tsdef'
import { daddy960_stocknerve_strategyGroup } from './daddy960_stocknerve_initStrategies'

type WatchListGroupNameType =
  | 'stocknerve_group_1'
  | 'stocknerve_group_2'
  | 'stocknerve_group_3'
  | 'stocknerve_group_4'
  | 'stocknerve_group_5'

export const watchListConfig: { groupName: WatchListGroupNameType; displayText: string }[] = [
  { groupName: 'stocknerve_group_1', displayText: '群組1' },
  { groupName: 'stocknerve_group_2', displayText: '群組2' },
  { groupName: 'stocknerve_group_3', displayText: '群組3' },
  { groupName: 'stocknerve_group_4', displayText: '群組4' },
  { groupName: 'stocknerve_group_5', displayText: '群組5' },
]

export const groupStore = proxy({
  /** 一般自選股用的 */
  watchListGroup: 'stocknerve_group_1' as WatchListGroupNameType,
  /** 股期分數排序用 */
  watchListData: 'stocknerve_group_1' as WatchListGroupNameType,
  /** 股期分數預設資料 */
  defaultData: true as boolean,
})

//----------

export const Daddy960_stocknerve_SidePane2 = memo<ReactProps>(
  function Daddy960_stocknerve_SidePane2() {
    const charting = useSnapshot(store.charting)
    const symbolNumber = charting.symbol
    const groupState = useSnapshot(groupStore)

    const strategyGroupConfig = daddy960_stocknerve_strategyGroup.main.map(s => s)

    return (
      <div
        css={css`
          ${fill_vertical_cross_center};
          user-select: none;
          padding: 4px;
        `}
      >
        <div
          css={css`
            ${fill_horizontal_all_center};
            height: 30px;
            gap: 8px;
          `}
        >
          <PageSwitch
            url='/heineken_template'
            keys='index'
          >
            股期看盤
          </PageSwitch>
          <PageSwitch
            url='/heineken_template/stocknerve/shareholding-distribution'
            keys='shareholding-distribution'
          >
            股權分散表
          </PageSwitch>
        </div>
        <TitleSymbolQuote.Default symbol={symbolNumber} />
        <styleds.trendChartContent>
          <IntradayTrendChart
            symbol={symbolNumber}
            ticksSize={13}
            ticksHeight={20}
            priceTicksMargin={2.5}
            priceTicksSize={13}
          />
        </styleds.trendChartContent>
        <styleds.watchListToggleSelector>
          <WatchSymbolToggleSelector config={watchListConfig} />
        </styleds.watchListToggleSelector>
        <SymbolInfoForm symbol={symbolNumber} />
        <styleds.watchLsitContent>
          <styleds.watchListGroupSwitchContent>
            {watchListConfig.map((item, index) => {
              const isSelect = item.groupName === groupState.watchListGroup
              return (
                <styleds.watchListGroupButton
                  key={index}
                  selected={isSelect}
                  onClick={() => (groupStore.watchListGroup = item.groupName)}
                >
                  {item.displayText}
                </styleds.watchListGroupButton>
              )
            })}
          </styleds.watchListGroupSwitchContent>
          <WatchListWithChart
            groupName={groupState.watchListGroup}
            useChart={store.charting}
          />
        </styleds.watchLsitContent>
      </div>
    )
  },
)

export const PageSwitch = memo<
  ReactProps<{ url: string; keys: 'index' | 'shareholding-distribution' }>
>(function PageSwitch(props) {
  const state = useSnapshot(stocknerve_store).pageState
  const selectedPage = state === props.keys
  const currentUrl = window.location.href

  useEffect(() => {
    if (currentUrl.includes('shareholding-distribution')) {
      stocknerve_store.pageState = 'shareholding-distribution'
    }
    if (!currentUrl.includes('shareholding-distribution')) {
      stocknerve_store.pageState = 'index'
    }
  }, [currentUrl])

  return (
    <styleds.switchButton selected={selectedPage}>
      <AppLink2
        href={props.url}
        css={css`
          ${fill_horizontal_all_center};
          color: ${selectedPage ? '#cacaca' : '#888888'};
        `}
      >
        {props.children}
        {selectedPage}
      </AppLink2>
    </styleds.switchButton>
  )
})

const styleds = {
  trendChartContent: styled.div`
    width: 100%;
    height: 164px;
    padding: 4px;
  `,
  watchListToggleSelector: styled.div`
    width: 100%;
    height: 48px;
    & > * {
      font-size: 14px !important;
    }
  `,
  watchListGroupSwitchContent: styled.div`
    ${fill_horizontal_all_center};
    width: 100%;
    height: 48px;
    gap: 8px;
    font-size: 14px;
  `,
  watchListGroupButton: styled.div<{ selected: boolean }>`
    ${fill_horizontal_all_center};
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    background-color: ${props => (props.selected ? '#4759cc' : '#252525')};
    &:hover {
      background-color: ${props => (props.selected ? '#4759cccc' : '#353535')};
    }
  `,
  watchLsitContent: styled.div`
    width: 100%;
    height: calc(100% - 400px);
  `,
  switchButton: styled.div<{ selected: boolean }>`
    ${fill_horizontal_all_center};
    width: 100%;
    height: 28px;
    background-color: #252525;
    border-radius: 4px;
    cursor: pointer;
    color: #ffffff;
    border: 1px solid ${props => (props.selected ? '#ffffff' : '#222222')};
    &:hover {
      background-color: #545454;
    }
  `,
}
