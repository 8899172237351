import { memo } from 'react-tracked'
import { proxy, useSnapshot } from 'valtio'
import {
  useDoubleVolumeMA,
  useTdccStock,
} from '~/modules/screener/containers/useStockScreenerResource'
import { staticStore } from '../heineken_template/_private/staticStore'
import styled from '@emotion/styled'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { scrollbar2Css } from '~/css/scrollbarCss'
import StockFuturesSymbol from './component/StockFuturesSymbol'
import { useEffect, useState } from 'react'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { css } from '@emotion/react'
import { Slider } from '@mantine/core'
import { daddy960_stocknerve_strategyGroup } from './daddy960_stocknerve_initStrategies'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'

export const stocknerve_store = proxy({
  indexType: false as boolean,
  stockValue: 2 as number,
})

const stockValueMark = [
  { value: 1, label: '5千萬以上' },
  { value: 2, label: '5億以上' },
  { value: 3, label: '20億以上' },
]

export const Daddy960_stocknerve_SidePane3 = memo<ReactProps>(
  function Daddy960_stocknerve_SidePane3() {
    const state = useSnapshot(stocknerve_store)
    const baseDate = useSnapshot(staticStore).tradedDate.intraday

    /** 雙均線選股資料 */
    const doubleVolumeMa = useDoubleVolumeMA({
      date: baseDate.format('YYYY-MM-DD'),
      ma1: 3,
      ma2: 20,
      volume_gte: 1000,
      amount_gte: state.stockValue === 1 ? 5e7 : state.stockValue === 2 ? 5e8 : 2e9,
    }).data

    /** 與上週相比的大戶增減 */
    const tdccSymbol = useTdccStock({
      date: baseDate.format('YYYY-MM-DD'),
      bs: 'b',
      weeks_ago: 1,
    }).data

    const stockFutures = useSnapshot(staticStore).symbol.stockFuturesMapper
    const stockFuturesSymbol = Object.keys(stockFutures ?? [])

    const doubleVolumeMaSymbol = doubleVolumeMa?.map(s => s)
    const screenerSymbol = doubleVolumeMaSymbol?.filter(item1 =>
      tdccSymbol?.some(item2 => item2 === item1),
    )

    const resultSymbol = screenerSymbol?.filter(item1 =>
      stockFuturesSymbol?.some(item2 => item2 === item1),
    )

    const futuresDisctionary = useSnapshot(staticStore.symbol.stockFuturesMapper)

    //根據點選的商狀態品 現貨/股期 來決定顯示的商品
    const symbolListArray =
      state.indexType === true
        ? resultSymbol
        : resultSymbol?.map(s => futuresDisctionary?.[s] + '-1')

    //根據點選的商品 現貨/股期 來決定顯示的指標
    const strategyGroupConfig = daddy960_stocknerve_strategyGroup.main.map(s => s)
    const config = state.indexType === true ? strategyGroupConfig[1] : strategyGroupConfig[0]

    return (
      <styleds.container>
        <styleds.dashboardTitle>
          <styleds.resultTitle>成交值篩選</styleds.resultTitle>
          <styleds.resultTitle>商品類別</styleds.resultTitle>
        </styleds.dashboardTitle>
        <div
          css={css`
            width: 100%;
            height: 64px;
          `}
        >
          <styleds.dashboard>
            <styleds.card
              css={css`
                padding: 0px 32px;
              `}
            >
              <Slider
                css={css`
                  width: 100%;
                `}
                color='indigo'
                radius='xs'
                min={1}
                max={3}
                step={1}
                marks={stockValueMark}
                label={null}
                labelTransition='fade'
                size={6}
                value={state.stockValue}
                onChange={value => {
                  stocknerve_store.stockValue = value
                }}
              />
            </styleds.card>
            <styleds.card
              onClick={() => {
                stocknerve_store.indexType = !state.indexType
              }}
            >
              <span>切換{state.indexType === true ? '期貨' : '現貨'} </span>
            </styleds.card>
          </styleds.dashboard>
        </div>

        <styleds.resultTitle>ABC選股結果：{resultSymbol?.length}檔</styleds.resultTitle>
        <StockFuturesSymbol.header />
        <styleds.listContent>
          <SymbolList
            symbol={symbolListArray ?? []}
            strategyConfig={config}
          />
        </styleds.listContent>
      </styleds.container>
    )
  },
)

const SymbolList = memo<
  ReactProps<{ symbol: string[]; strategyConfig: ChartTypes.StrategyConfig }>
>(function SymbolList(props) {
  useEffect(() => {
    signalrStore2.addQuote(props.symbol)
    return () => {
      signalrStore2.removeQuote(props.symbol)
    }
  }, [JSON.stringify(props.symbol)])

  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        gap: 4px;
        padding: 4px;
      `}
    >
      {props.symbol?.map(symbol => {
        return (
          <StockFuturesSymbol.body
            key={symbol}
            symbol={symbol}
            strategyConfig={props.strategyConfig}
          />
        )
      })}
    </div>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #121212;
    user-select: none;
    padding: 4px;
    gap: 4px;
  `,
  listContent: styled.div`
    ${fill_vertical_all_center};
    ${scrollbar2Css};
    height: calc(100% - 176px);
    background-color: #1f1f1f;
    gap: 4px;
  `,
  resultTitle: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    background-color: #252525;
    border-radius: 5px;
  `,
  dashboardTitle: styled.div`
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: calc(70% - 2px) calc(30% - 2px);
    height: 30px;
    grid-gap: 4px;
  `,
  dashboard: styled.div`
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: calc(70% - 2px) calc(30% - 2px);
    height: 64px;
    grid-gap: 4px;
  `,
  card: styled.div`
    ${fill_vertical_all_center};
    background-color: #1f1f1f;
    border-radius: 4px;
    font-size: 14px;
    & > * {
      &:hover {
        cursor: pointer;
        background-color: #2f2f2f;
      }
    }
  `,
}
